import React, { useRef, useCallback, useState } from "react";
import "./App.css";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import SocialButtons from "./Social";
import particlesConfig from "./particlesConfig.json"; // Icons from https://simpleicons.org
import Project from "./Project";
import Stack from "./Stack";
import ConfettiExplosion from "react-confetti-explosion";
import projects from "./myProjects.json";
import CustomCarousel from "./Carousel";

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
};

const App = () => {
    const projectsRef = useRef(null);
    const stackRef = useRef(null);
    const mediaRef = useRef(null);
    const [isExploding, setIsExploding] = useState(false);

    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    const handleScrollToProjects = () => {
        projectsRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleScrollToStack = () => {
        stackRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleScrollToMedia = () => {
        mediaRef.current.scrollIntoView({ behavior: "smooth" });
    };


    return (
        <div className="app-container">
            {!isMobileDevice() && (
                <Particles
                    id="tsparticles"
                    className="particles-container"
                    init={particlesInit}
                    options={particlesConfig}
                />
            )}
            <div className="main-content">
                <h1 className="name">Yoav Tepper</h1>
                <div className="typewriter">
                    <h2>
                        Creative Coding Content
                    </h2>
                </div>
                <button className="button" onClick={handleScrollToProjects}>
                    Projects
                </button>
                <button className="button" onClick={handleScrollToMedia}>
                    On Media
                </button>
                <button className="button" onClick={handleScrollToStack}>
                    Stack
                </button>
                {!isExploding && <button className="button" onClick={() => setIsExploding(true)}>
                 PARTY!
                </button>}
                {isExploding && (
                    <ConfettiExplosion
                        force={1}
                        duration={3000}
                        particleCount={250}
                        height={"1700px"}
                    />
                )}
                <SocialButtons />
            </div>
            <div className="projects-section" ref={projectsRef} id="projects">
                {projects.map((project) => (
                    <Project project={project} />
                ))}
            </div>
            <div className="media-section" ref={mediaRef} id="media">
                <CustomCarousel />
            </div>
            <div className="stack-section" ref={stackRef} id="stack">
                <Stack />
            </div>
        </div>
    );
};

export default App;
