import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
  );
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Project({project}) {
  const [expanded, setExpanded] = React.useState(isMobileDevice());

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ backgroundColor: 'white', width: "80vw", maxWidth: 360, margin: 3 }}>
      <CardHeader
        title={<span style={{fontSize: "1em", fontFamily: "Roboto Mono"}}>{project.title}</span>}
        subheader={<span style={{fontFamily: "Roboto Mono"}}>{project.subheader}</span>}
      />
            <CardContent>
        <Typography fontFamily={"Roboto Mono"} variant="body2" color="text.secondary">
          {project.description}
        </Typography>
      </CardContent>
    <a href={project.url} target="_blank" rel="noopener noreferrer">
        <CardMedia
          component="img"
          height="360"
          image={project.image}
          alt={project.title}
        />
      </a>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* {project.publications.length ? <Typography style={{fontFamily: "courier"}}>On The News:</Typography> : <></>} */}
          {project.publications.map((publication) => 
          <Typography style={{textAlign: "left", fontFamily: "Roboto Mono"}} >
            <li>
            <a href={publication.url} target="_blank" rel="noopener noreferrer">{publication.displayText}</a>
            </li>
          </Typography>)}
        </CardContent>
      </Collapse>
    </Card>
  );
}