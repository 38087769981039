import { FaGithub, FaLinkedin, FaTwitter, FaCoffee } from "react-icons/fa";
import "./App.css";

export default function SocialButtons() {
    return (
        <div className="social-icons">
            <a
                href="https://www.linkedin.com/in/yoav-tepper-30283b131"
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
            >
                <FaLinkedin color="white" size={40} className="social-icon" />
            </a>
            <a
                href="https://github.com/SgtTepper"
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
            >
                <FaGithub color="white" size={40} className="social-icon" />
            </a>
            <a
                href="https://twitter.com/TepperYoav"
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
            >
                <FaTwitter color="white" size={40} className="social-icon" />
            </a>
            <a
                href="https://ko-fi.com/yoavtepper"
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
            >
                <FaCoffee color="white" size={40} className="social-icon" />
            </a>
        </div>
    );
}
