import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./App.css";
import { Typography } from "@mui/material";

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
};

const projects = [
    { imageUrl: '10.png', label: 'NBC New York'},
    { imageUrl: '1.png', label: 'Israeli MFA'},
    { imageUrl: '18.png', label: 'Time Of Israel'},
    { imageUrl: '19.png', label: 'Live TV (13 news prime time)'},
    { imageUrl: '20.png', label: 'Live TV (12 news prime time)'},
    { imageUrl: '17.jpeg', label: 'Live TV (former politician playing RentWTF)'},
    { imageUrl: '9.png', label: 'Scientific Report (By Nature)'},
    { imageUrl: '2.png', label: 'Geektime'},
    { imageUrl: '4.png', label: 'Ynet'},
    { imageUrl: '3.png', label: 'Geektime'},
    { imageUrl: '16.png', label: 'Globes'},
    { imageUrl: '6.png', label: 'Mako (by 12 news)'},
    { imageUrl: '5.png', label: 'Geektime'},
    { imageUrl: '11.png', label: 'The Marker'},
    { imageUrl: '7.png', label: 'Geektime'},
    { imageUrl: '8.png', label: 'Ynet'},
    { imageUrl: '12.png', label: 'Timeout'},
    { imageUrl: '14.png', label: 'Mako (by 12 news)'},
    { imageUrl: '13.png', label: 'Timeout'},
    { imageUrl: '15.png', label: 'Geektime'},
  ];

export default function carouCustomCarousel() {
    return (
            <Carousel
                className="carousel"
                autoPlay={true}
                infiniteLoop={true}
                interval={4000}
                showArrows={true}
                useKeyboardArrows={true}
                showThumbs={false}
            >
            {projects.map((project) => (
                <div style={isMobileDevice() ? {} : {height: '100dvh'}} className="slide-content">
                    <img style={isMobileDevice() ? {} : {height: '70%'}} src={project.imageUrl} alt={project.label}></img>
                    <p className="legend"><span style={{fontSize: '1.3em'}}>{project.label}</span></p>
                </div>
            ))}
            </Carousel>
    );
}